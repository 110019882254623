import { Quality, Questionnaire } from '@hulder/client-common/model';
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from 'react-redux';
import { combineReducers, Reducer } from "redux";
import thunk from 'redux-thunk';
import * as uuid from 'uuid';
import { CreateQuestionnaireError, QualityAction, QuestionnaireCreatedAction } from './actions';

const loadQualities = () => {
  const qualities = sessionStorage.getItem('qualities');

  if (qualities === null) return [];

  try {
    return JSON.parse(qualities);
  } catch (e) {
    return [];
  }
}

const loadQuestionnaire = () => {
  const questionnaire = sessionStorage.getItem("questionnaire");

  if (questionnaire === null) return null;

  try {
    return JSON.parse(questionnaire);
  } catch (e) {
    return null;
  }
};

const qualitiesReducer: Reducer<Quality[], QualityAction | QuestionnaireCreatedAction> = (
  state = loadQualities(),
  action
) => {
  switch (action.type) {
    case "CREATE_QUALITY":
      return [
        ...state,
        {
          id: uuid.v4(),
          description: action.payload.description,
          questionnaireCount: 0,
        },
      ];
    case "QUESTIONNAIRE_CREATED":
      return state.map(s => {
        return {
          ...s,
          questionnaireCount: action.payload.questionnaire.qualities?.find(
            (q) => q.id === s.id
          )
            ? 1
            : 0,
        };
      });
    case "DELETE_QUALITY":
      return state.filter(q => q.id !== action.payload.id);
    default:
      return state;
  }
};

const questionnaireReducer: Reducer<
  Questionnaire | null,
  QuestionnaireCreatedAction
> = (state = loadQuestionnaire(), action) => {
  switch (action.type) {
    case "QUESTIONNAIRE_CREATED":
      return action.payload.questionnaire;
    default:
      return state;
  }
};

const errorsReducer: Reducer<
  { questionnaire: any },
  CreateQuestionnaireError
> = (
  state = {
    questionnaire: null,
  },
  action
) => {
  switch (action.type) {
    case "CREATE_QUESTIONNAIRE_ERROR":
      return { ...state, questionnaire: action.payload };
    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  qualities: qualitiesReducer,
  questionnaire: questionnaireReducer,
  errors: errorsReducer,
});

export type ReducerState<T> = T extends Reducer<infer S, any> ? S : never;
export type ReducerAction<T> = T extends Reducer<any, infer A> ? A : never;
export type RootReducerState = ReducerState<typeof rootReducer>;
export type RootReducerAction = ReducerAction<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([thunk]),
});

export type StoreDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<StoreDispatch>();
export function useAppSelector<TSelected = unknown>(
  selector: (state: RootReducerState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  return useSelector<RootReducerState, TSelected>(selector, equalityFn);
}

store.subscribe(() => {
  const state = store.getState();

  localStorage.setItem('qualities', JSON.stringify(state.qualities));
  sessionStorage.setItem('questionnaire', JSON.stringify(state.questionnaire));
});