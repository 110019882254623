import { createContext, useContext, useEffect, useState } from "react";

export const WebSocketContext = createContext<WebSocket | null>(null);

export const useWebSocket = (): WebSocket => {
  const ws = useContext(WebSocketContext);

  if (!ws) {
    throw new Error('WebSocketContext not provided');
  }

  return ws;
}

export const WebSocketProvider: React.FC<{ ws?: WebSocket }> = ({ children }) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const url = window.location.origin.replace(/^http/, "ws") + "/ws";

  if (!ws) {
    setWs(new WebSocket(url));
  }

  useEffect(() => {
    ws?.addEventListener("close", () => {
      setTimeout(() => {
        setWs(new WebSocket(url));
      }, 1000);
    });
  }, [ws, url]);


  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};