import { useMeQuery, User } from '@hulder/hulder-spec-client';
import React, { useContext } from "react";

export const UserContext = React.createContext<{ me?: User, loading: boolean }>({ loading: true });

export const useMe = () => useContext(UserContext);

export const UserProvider: React.FC = ({ children }) => {
    const { data: { me } = {}, loading, error } = useMeQuery();

    return <UserContext.Provider value={{ me: me as User, loading }}>
        {children}
    </UserContext.Provider>
}

export default UserProvider;
