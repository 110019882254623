import { Quality } from "@hulder/client-common/model";
import React, { useState } from "react";
import { useMe } from "../context/UserProvider";
import Checkbox from "./Checkbox";
import IconButton from "./IconButton";
import SearchIcon from "./icons/Search";
import styles from "./QualitySelector.module.scss";
import { TextArea } from "./TextField";

interface QualityFilterFieldProps {
  filter?: string;
  onFilterChanged?: (value: string) => void;
  onClick?: () => void;
  onSubmit?: () => void;
}
const QualityFilterField: React.FC<QualityFilterFieldProps> = ({
  filter,
  onFilterChanged,
  onSubmit,
}) => {
  const [focus, setFocus] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    onSubmit && onSubmit();
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.substring(0, limit);

    onFilterChanged && onFilterChanged(value);
  };

  const limit = 80;

  return (
    <div className={styles["quality-filter-field"]}>
      <SearchIcon
        className={styles["quality-filter-search-icon"]}
        style={focus ? { display: "none" } : {}}
      />
      <form
        onSubmit={handleSubmit}
        className={styles['quality-filter-form']}
      >
        <TextArea
          onBlur={handleBlur}
          onFocus={handleFocus}
          rows={2}
          className={styles["quality-filter-input"]}
          placeholder={`Filteren/aanmaken...`}
          value={filter}
          onChange={handleChange}
        />
        {focus ? (
          <div className={styles["quality-filter-field-limit"]}>{`${
            filter?.length || 0
          }/${limit}`}</div>
        ) : null}
      </form>
    </div>
  );
};

interface QualityListItemProps {
  quality: Quality;
  checked: boolean;
  onChangeChecked?: (checked: boolean) => void;
  onDeleteClicked?: () => Promise<void> | void;
}

const QualityListItem: React.FC<QualityListItemProps> = ({
  quality,
  checked,
  onChangeChecked,
  onDeleteClicked,
}) => {
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const handleDeleteClicked = async () => {
    if (onDeleteClicked) {
      setDeleteInProgress(true);
      await onDeleteClicked();
      setDeleteInProgress(false);
    }
  };

  return (
    <div className={styles.item} key={quality.id}>
      <Checkbox
        id={quality.id}
        className={styles.itemCheckbox}
        checked={checked}
        onChange={(e) => onChangeChecked && onChangeChecked(e.target.checked)}
      />{" "}
      <label className={styles.itemLabel} htmlFor={quality.id}>
        {quality.description}
      </label>
      {quality.questionnaireCount != null && quality.questionnaireCount < 1 && (
        <IconButton
          inProgress={deleteInProgress}
          disabled={deleteInProgress}
          onClick={handleDeleteClicked}
          className={styles["quality-delete-button"]}
          transparent={true}
        >
          <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"></path>
          </svg>
        </IconButton>
      )}
    </div>
  );
};

export const QualitySelector: React.FC<{
  qualities: Quality[];
  selected: string[];
  onChangeSelected: (qualities: string[]) => void;
  onCreateQuality?: (description: string) => Promise<void>;
  onDeleteQuality?: (id: string) => Promise<void>;
}> = ({
  qualities,
  selected,
  onChangeSelected,
  onCreateQuality,
  onDeleteQuality,
}) => {
  const { me } = useMe();

  const [qualityFilter, setQualityFilter] = useState("");
  const [createInProgress, setCreateInProgress] = useState(false);

  const handleCreateQualityClicked = async () => {
    if (qualityFilter.trim().length === 0) {
      return;
    }

    try {
      setCreateInProgress(true);
      await onCreateQuality.apply(null, [qualityFilter]);

      setQualityFilter("");
      setCreateInProgress(false);
    } catch (e) {
      setCreateInProgress(false);
      console.error(e);
    }
  };

  const handleDelete = (id: string) => async () => {
    await onDeleteQuality.apply(null, [id]);
  };

  const handleChangeChecked = (id: string) => (checked: boolean) => {
    if (checked) {
      onChangeSelected([...new Set([...selected, id])]);
    } else {
      onChangeSelected(selected.filter((sel) => sel !== id));
    }
  };

  const filteredQualities =
    qualityFilter?.length > 0
      ? qualities.filter((q) =>
          q.description?.toLowerCase().match(qualityFilter.toLowerCase())
        )
      : qualities;

  return (
    <div className={styles['container']}>
      <div className={styles["quality-filter-container"]}>
        <QualityFilterField
          filter={qualityFilter}
          onFilterChanged={(value) => setQualityFilter(value)}
          onSubmit={handleCreateQualityClicked}
        />
        {qualityFilter.trim().length > 0 ? (
          <IconButton
            inProgress={createInProgress}
            disabled={createInProgress}
            onClick={handleCreateQualityClicked}
            transparent={true}
          >
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
            </svg>
          </IconButton>
        ) : null}
      </div>
      <div className={styles["items-container"]}>
        {filteredQualities.map((quality) => (
          <QualityListItem
            key={quality.id}
            quality={quality}
            checked={selected.includes(quality.id)}
            onChangeChecked={handleChangeChecked(quality.id)}
            onDeleteClicked={handleDelete(quality.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default QualitySelector;
