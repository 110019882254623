import { useQRDataUri } from '../util/qr-code';
import styles from './QRCode.module.scss';

export const QRCode: React.FC<{
  data?: string;
  imageClass?: string;
  skeletonClass?: string;
}> = ({ data, imageClass, skeletonClass }) => {
  const dataUri = useQRDataUri(data);

  const imageClasses = [styles['qr-image'], imageClass].filter(x => x);
  const skeletonClasses = [styles['skeleton'], skeletonClass].filter(x => x)

  return dataUri ? (
    <img className={imageClasses.join(' ')} src={dataUri} />
  ) : (
    <div className={skeletonClasses.join(' ')}></div>
  );
};