import { Easing, Tween } from "@tweenjs/tween.js";
import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import styles from "./Carousel.module.scss";

const Arrow =
  (
    svg: React.ReactElement,
    className?: string
  ): React.FC<React.ComponentProps<"svg">> =>
  (props) => {
    return React.createElement(svg.type, {
      ...svg.props,
      ...props,
      className: [className, svg.props.className, props.className].join(" "),
    });
  };

const arrowSvg = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="27.8px"
    height="27.8px"
    viewBox="0 0 27.8 27.8"
  >
    <g>
      <circle cx="13.9" cy="13.9" r="13.9" />
      <path d="M18,7.4l-2-2l-8.5,8.5l8.5,8.5l2-2l-6.5-6.5L18,7.4z" />
    </g>
  </svg>
);

const ArrowLeft = Arrow(arrowSvg, styles["arrow-left"]);
const ArrowRight = Arrow(arrowSvg, styles["arrow-right"]);

export const CarouselItem: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={[styles["carousel-item"], className].join(" ")}>
      {children}
    </div>
  );
};

export interface CarouselProps {
  index: number;
  className?: string;
  arrowClass?: string;
  onChangeIndex?: (index: number) => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  children,
  index,
  className,
  arrowClass,
  onChangeIndex,
}) => {
  const numItems = React.Children.toArray(children).length;

  const [tweening, setTweening] = useState(false);
  const refScroll = useRef(0);

  const refSlides = useRef<HTMLDivElement | null>(null);
  const refTween = useRef<Tween<{ scrollLeft: number }> | null>(null);
  const refClientX = useRef<number>(0);
  const refSwipeDelta = useRef<number>(0);
  const refIndex = useRef<number>(0);
  const swipeDelta = 10;

  const refFnSlides = (node: HTMLDivElement | null) => {
    refSlides.current = node;

    if (!node) return;

    node.scrollLeft = refScroll.current;
  };

  const scrollToIndex = useCallback(
    (index: number) => {
      const _index = Math.max(0, Math.min(numItems, index));

      const rect = refSlides.current!!.getBoundingClientRect();
      refTween.current?.stop();

      setTweening(true);

      refTween.current = new Tween({
        scrollLeft: refSlides.current!!.scrollLeft,
      })
        .duration(200)
        .easing(Easing.Quadratic.InOut)
        .to({
          scrollLeft: _index * rect.width,
        })
        .onUpdate(({ scrollLeft }) => {
          refScroll.current = scrollLeft;
          refSlides.current!!.scrollLeft = scrollLeft;
        })
        .onComplete(() => {
          setTweening(false);
          if (refSlides.current)
            refSlides.current.classList.remove(styles["no-snap"]);
        })
        .start();
    },
    [refSlides, onChangeIndex]
  );

  const handleTouchStart = (e: React.TouchEvent) => {
    refClientX.current = e.touches[0].clientX;
    refSwipeDelta.current = 0;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    refSwipeDelta.current = e.touches[0].clientX - refClientX.current;
    refClientX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (Math.abs(refSwipeDelta.current) >= swipeDelta) {
      const index = Math.min(numItems - 1, Math.max(0, refIndex.current - Math.sign(refSwipeDelta.current)));

      onChangeIndex(index);
    }
  };

  useEffect(() => {
    refIndex.current = index;
    scrollToIndex(index);
  }, [index]);

  const navigateLeft = useCallback(() => {
    onChangeIndex(Math.max(0, index - 1));
  }, [index]);

  const navigateRight = useCallback(() => {
    onChangeIndex(Math.min(numItems - 1, index + 1));
  }, [numItems, index]);

  return (
    <div className={[styles["carousel"], className].join(" ")}>
      <div
        className={`${styles["carousel-chevron-space"]} ${
          index === 0 ? styles["hidden"] : ""
        }`}
        onClick={(e) => navigateLeft()}
      >
        <ArrowLeft className={arrowClass} />
      </div>
      <div
        ref={refFnSlides}
        className={`${[
          styles["carousel-slides"],
          tweening ? styles["no-snap"] : null,
        ].join(" ")}`}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {children}
      </div>
      <div
        className={`${styles["carousel-chevron-space"]} ${
          index === numItems - 1 ? styles["hidden"] : ""
        }`}
        onClick={(e) => navigateRight()}
      >
        <ArrowRight className={arrowClass} />
      </div>
    </div>
  );
};

export default Carousel;
