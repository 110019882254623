import { useEffect, useRef, useState } from "react";

export const IndeterminateCircularProgress: React.FC<{
  size?: number;
  strokeWidth?: number;
  stroke?: string;
} & React.ComponentProps<'svg'>> = ({ size = 32, strokeWidth = 4, stroke = "black", ...svgProps }) => {
  const [t, setT] = useState(0);
  const refT = useRef(0);
  const r = size / 2;
  const circumference = Math.PI * 2 * r;

  useEffect(() => {
    let handle: number;
    const update = () => {
      handle = requestAnimationFrame(update);

      setT(refT.current);
      refT.current += 1 / 60;
    };

    update();

    return () => {
      cancelAnimationFrame(handle);
    };
  }, []);

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} {...svgProps}>
      <circle
        r={r - strokeWidth}
        transform={`translate(${r} ${r}) rotate(${t * 180} 0 0)`}
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={(circumference * (1 - t / 2)) % (circumference * 2)}
      />
    </svg>
  );
};

export default IndeterminateCircularProgress;
