import React, { ComponentProps, forwardRef } from "react";
import styles from "./TextField.module.scss";

export const TextArea = forwardRef<HTMLTextAreaElement, ComponentProps<'textarea'>>((props, ref) => {
    return <textarea
        ref={ref}
        {...props}
        className={[props.className, styles.default, styles.textArea].join(' ')}
    />
});

export const TextField = forwardRef<HTMLInputElement, ComponentProps<'input'>>((props, ref) => {
    return <input
        ref={ref}
        {...props}
        className={[props.className, styles.default, styles.textArea].join(' ')}
        type={props.type === 'password' ? 'password' : 'text'}
    />
});