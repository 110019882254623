// @ts-nocheck

import React, { useCallback, useEffect, useRef } from "react";
import * as d3 from "d3";
import { max, range } from "lodash";
import styles from "./RadarChart.module.scss";
import { Quality } from "@hulder/hulder-spec-client";

interface RadarChartProps {
  data: { quality: Quality; count: number }[];
  highlight: string | null;
  onHover?: (id: string | null) => void;
  onSelect?: (id: string | null) => void;
}

interface RadarChartData {
  quality: Quality;
  count: number;
}

function usePrevious<T>(value: T) {
  const previous = useRef<T>();

  useEffect(() => {
    previous.current = value;
  }, [value]);

  return previous.current;
}

export const RadarChart: React.FC<RadarChartProps> = ({
  data,
  onHover,
  onSelect,
  highlight,
}) => {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const refSvg = useRef(d3.create("svg"));

  const radiusInner = 32;
  const radiusOuter = 128 - 15;

  const maxValue = max(data.map((d) => d.count))!!;

  const theta = d3.scaleLinear(
    [0, data.length],
    [-Math.PI / 2, Math.PI * (3 / 2)]
  );
  const rho = d3.scaleLinear([0, maxValue], [radiusInner, radiusOuter]);

  const handleMouseOver = useCallback(
    (e: MouseEvent) => {
      const node = e.target as SVGElement;
      if (!node.classList.contains(styles["dot"])) return;

      const id = node.getAttribute("data-id");

      onHover?.apply(null, [id]);
    },
    [onHover]
  );

  const handleMouseOut = useCallback(
    (e: MouseEvent) => {
      const node = e.target as SVGElement;
      if (!node.classList.contains(styles["dot"])) return;

      onHover?.apply(null, [null]);
    },
    [onHover]
  );

  const previousHandleMouseOver = usePrevious(handleMouseOver);
  const previousHandleMouseOut = usePrevious(handleMouseOut);

  useEffect(() => {
    const svg = refSvg.current;
    svg.attr("width", 256);
    svg.attr("height", 256);
    svg.attr("viewBox", "0 0 256 256");

    const g = svg.append("g").attr("transform", `translate(128 128)`);

    g.append("g").attr("id", "axes");
    g.append("g").attr("id", "rings");
    g.append("path").attr("class", styles["area"]);
    g.append("g").attr("id", "dots");
  }, []);

  useEffect(() => {
    const node = refSvg.current.node()!!;

    if (previousHandleMouseOver)
      node.removeEventListener("mouseover", previousHandleMouseOver);
    if (previousHandleMouseOut)
      node.removeEventListener("mouseout", previousHandleMouseOut);

    node.addEventListener("mouseout", handleMouseOut);
    node.addEventListener("mouseover", handleMouseOver);
  }, [
    handleMouseOut,
    handleMouseOver,
    previousHandleMouseOut,
    previousHandleMouseOver,
  ]);

  useEffect(() => {
    const maxValue = max(data.map((d) => d.count))!!;

    const svg = refSvg.current;
    const dots = svg.select("#dots").selectAll(`.${styles["dot"]}`).data(data);
    const axes = svg.select("#axes").selectAll(`.${styles["axis"]}`).data(data);
    const rings = svg
      .select("#rings")
      .selectAll(`.${styles["ring"]}`)
      .data(range(0, 3));

    const highlightedQualityIndex = data.findIndex(
      (q) => q.quality.id === highlight
    );
    const highlightDot = svg
      .select("#dots")
      .selectAll(`.${styles["dot-highlight"]}`)
      .data(
        highlightedQualityIndex >= 0
          ? [
              {
                index: highlightedQualityIndex,
                data: data[highlightedQualityIndex],
              },
            ]
          : []
      );

    const enterDots = dots.enter().append("circle");
    const enterAxes = axes.enter().append("line");
    const enterRings = rings.enter().append("circle");
    const enterHighlight = highlightDot.enter().append("circle");

    dots.exit().remove();
    axes.exit().remove();
    rings.exit().remove();
    highlightDot.exit().remove();

    enterDots
      .merge(dots)
      .attr("class", styles["dot"])
      .attr("r", 8)
      .attr("data-id", (d: RadarChartData) => d.quality.id)
      .on("click", ({ target }: { target: SVGElement }) =>
        onSelect?.apply(null, [target.dataset.id])
      )
      .attr(
        "transform",
        (d, i) =>
          `translate(${Math.cos(theta(i)) * rho(d.count)} ${
            Math.sin(theta(i)) * rho(d.count)
          })`
      );

    enterHighlight
      .merge(highlightDot)
      .attr("class", styles["dot-highlight"])
      .attr("r", 12)
      .attr(
        "transform",
        (d) =>
          `translate(${Math.cos(theta(d.index)) * rho(d.data.count)}  ${
            Math.sin(theta(d.index)) * rho(d.data.count)
          })`
      );

    enterAxes
      .merge(axes)
      .attr("class", styles["axis"])
      .attr("x0", 0)
      .attr("y0", 0)
      .attr(
        "x1",
        (d: RadarChartData, i: number) => Math.cos(theta(i)) * rho(maxValue)
      )
      .attr(
        "y1",
        (d: RadarChartData, i: number) => Math.sin(theta(i)) * rho(maxValue)
      );

    enterRings
      .merge(rings)
      .attr("class", styles["ring"])
      .attr("r", (d, i) => {
        return rho((maxValue / 2) * d);
      });

    const point = (i: number): [number, number] => [
      Math.cos(theta(i)) * rho(data[i].count),
      Math.sin(theta(i)) * rho(data[i].count),
    ];

    const path = d3.path();

    path.moveTo(...point(0));
    for (let i = 1; i < data.length; i++) {
      path.lineTo(...point(i));
    }
    path.closePath();

    svg.select(`.${styles["area"]}`).attr("d", path.toString());
  }, [data]);

  const refFnContainer = (node: HTMLDivElement) => {
    refContainer.current = node;
    if (!node) return;

    while (node.lastChild) node.removeChild(node.lastChild);
    node.appendChild(refSvg.current!!.node());
  };

  return <div className={styles["chart"]} ref={refFnContainer} />;
};

export default RadarChart;
