import { Quality, Questionnaire } from '@hulder/client-common/model';
import { createAction } from '@reduxjs/toolkit';
import { StoreDispatch } from '.';
import * as uuid from 'uuid';
import * as api from "../api";
import axios from 'axios';

export type Action<T, P> = {type: T, payload: P};

export type CreateQualityAction = Action<'CREATE_QUALITY', { description: string }>;
export type DeleteQualityAction = Action<'DELETE_QUALITY', { id: string }>;

export type QuestionnaireCreatedAction = Action<
  "QUESTIONNAIRE_CREATED",
  { questionnaire: Questionnaire }
>;

export type CreateQuestionnaireError = Action<"CREATE_QUESTIONNAIRE_ERROR", {}>;

export type QualityAction = CreateQualityAction | DeleteQualityAction;
export type QuestionnaireAction = QuestionnaireCreatedAction;

export const createQuality = createAction<
  CreateQualityAction["payload"],
  "CREATE_QUALITY"
>("CREATE_QUALITY");

export const deleteQuality = createAction<
  DeleteQualityAction["payload"],
  "DELETE_QUALITY"
>("DELETE_QUALITY");

export const questionnaireCreated = createAction<
  QuestionnaireCreatedAction["payload"],
  "QUESTIONNAIRE_CREATED"
>("QUESTIONNAIRE_CREATED");

export const createQuestionnaireError = createAction<
  CreateQuestionnaireError["payload"],
  "CREATE_QUESTIONNAIRE_ERROR"
>("CREATE_QUESTIONNAIRE_ERROR");

export const createQuestionnaire = ({
  title,
  qualities,
  secret,
}: {
  title?: string;
  qualities: Quality[];
  secret: string;
}) => {
  return async (dispatch: StoreDispatch) => {
    const response = await api.createQuestionnaire({
      title,
      qualities,
      secret
    });

    dispatch(
      questionnaireCreated({ questionnaire: response.data })
    );

    return response.data;
  };
};