import Button from '@hulder/client-common/components/Button';
import { Quality } from "@hulder/client-common/types";
import {
  Questionnaire, User,
  VoteInput
} from "@hulder/hulder-spec-client";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Carousel, { CarouselItem } from '../Carousel';
import ProgressBar from "../ProgressBar";
import { TextArea } from "../TextField";
import styles from "./index.module.scss";
import QualityCheckbox from './QualityCheckbox';
import IntroPage from './IntroPage';

export const Voting: React.FC<{
  qualities: Quality[];
  questionnaire: Questionnaire;
  user: User;
  onSubmit?: (votes: VoteInput[]) => void | Promise<void>
}> = ({ qualities, questionnaire, user, onSubmit }) => {
  const [index, setIndex] = useState<number>(0);
  const [votes, setVotes] = useState<VoteInput[]>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setVotes(
      qualities.map((quality) => {
        return {
          hasVote: false,
          qualityId: quality.id,
        };
      })
    );
  }, [qualities]);

  const handleIndex = (ind: number) => {
    setIndex(Math.min(qualities.length - 1, ind | 0));
  };

  const submit = async () => {
    await onSubmit(votes);
  };

  const handleChange = (id: string) => (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    setVotes(
      votes.map((vote) => {
        if (vote.qualityId === id) {
          vote.hasVote = target.checked;
        }

        return vote;
      })
    );
  };

  const handleRemarksChanged = (id: string) => (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    setVotes(
      votes.map((vote) => {
        if (vote.qualityId === id) {
          vote.remarks = target.value;
        }

        return vote;
      })
    );
  };

  if (page === 0) {
    return (
      <div>
        <IntroPage
          userFullName={questionnaire?.user?.fullName}
          questionnaireDescription={questionnaire?.description}
        />
        <p />
        <Button onClick={(e) => setPage(1)}>Verder</Button>
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <span className={styles['center-text']}>Ik waardeer {questionnaire.user?.fullName || 'de Hulder-gebruiker'} voor zijn/haar:</span>
      <Carousel index={index} onChangeIndex={handleIndex}>
        {qualities.map((quality) => (
          <CarouselItem key={quality.id}>
            {votes.find((vote) => vote.qualityId === quality.id)?.hasVote}
            <div className={styles['item-title']}>
              <span className={styles["item-title-inner"]}>
                {quality.description}
              </span>
            </div>
            <div className={styles.checkboxContainer}>
              <QualityCheckbox
                checked={
                  votes.find((vote) => vote.qualityId === quality.id)?.hasVote ||
                  false
                }
                onChange={handleChange(quality.id)}
              />
            </div>
            <TextArea
              className={styles["remarks-field"]}
              name="description"
              placeholder="Toelichting"
              rows={3}
              value={
                votes.find((vote) => vote.qualityId === quality.id)?.remarks ||
                ""
              }
              onChange={handleRemarksChanged(quality.id)}
            />
          </CarouselItem>
        ))}
      </Carousel>
      <ProgressBar
        className={styles.progressBar}
        progression={index + 1}
        total={qualities.length}
      />
      <Button
        className={styles.submitButton}
        disabled={index + 1 < qualities.length}
        onClick={submit}
      >
        Voltooien
      </Button>
    </div>
  );
};

export default Voting;
