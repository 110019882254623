import styles from './IntroPage.module.scss';

export const IntroPage: React.FC<{ userFullName?: string, questionnaireDescription?: string }> = ({ userFullName, questionnaireDescription }) => {
  return (
    <span className={styles.introductionText}>
      Welkom op Hulder!
      <br />
      <br />
      {userFullName || "Iemand"} vraagt jou om je waardering te
      geven
      {questionnaireDescription &&
        ` in het kader van "${questionnaireDescription}"`}
      .<br />
      <br />
      Waardeer je wat hij/zij aangegeven heeft? Klik dan op het vinkje. Waardeer
      je hem of haar hier niet voor? Sla dit item dan over. Je kunt je stem
      eventueel verder toelichten in het tekstvak onder het vinkje.
      <br />
      <br />
      Geen waardering, ééntje, of allemaal? Jij mag het aangeven!
    </span>
  );
};

export default IntroPage;