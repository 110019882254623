import Button from "@hulder/client-common/components/Button";
import QualitySelector from '@hulder/client-common/components/QualitySelector';
import { TextField } from '@hulder/client-common/components/TextField';
import { Quality } from "@hulder/client-common/model";
import { useState } from "react";
import { useHistory } from "react-router";
import Container from "../components/Container";
import { Text } from "../components/Text";
import { Title } from "../components/Title";
import { useAppDispatch, useAppSelector } from "../redux";
import { createQuality, createQuestionnaire, deleteQuality } from "../redux/actions";
import styles from './CreateQuestionnaire.module.scss';

export const CreateQuestionnaire = () => {
  const qualities = useAppSelector(state => state.qualities);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [selected, setSelected] = useState<string[]>([]);
  const [createInProgress, setCreateInProgress] = useState(false);

  const formValid = selected.length > 0;

  const handleCreateQuality = async (description: string) => {
    dispatch(createQuality({ description }));
  }

  const handleDeleteQuality = async (id: string) => {
    dispatch(deleteQuality({ id }));
  }

  const handleChangeSelected = (selected: string[]) => {
    setSelected(selected);
  }

  const handleCreateClicked = async () => {
    const secret = [...crypto.getRandomValues(new Uint8Array(16))]
      .map((x) => x.toString(16))
      .join("");

    sessionStorage.setItem('questionnaire_secret', secret);

    const createQuestionnaireAction = createQuestionnaire({
      secret,
      qualities: selected
        .map((id) => qualities.find((q) => q.id === id))
        .filter((x) => typeof x !== "undefined") as Quality[],
    });

    try {
      setCreateInProgress(true);
      await dispatch(createQuestionnaireAction);
      setCreateInProgress(false);

      history.push('/resultaten');
    } catch (e) {
      console.error(e);
      setCreateInProgress(false);
    }
  }

  return (
    <Container className={styles["container"]}>
      <Title>Waarderingslijst maken</Title>
      <Text>Kies je waarderingen:</Text>
      <div className={styles["selector-container"]}>
        <QualitySelector
          qualities={qualities}
          selected={selected}
          onChangeSelected={handleChangeSelected}
          onCreateQuality={handleCreateQuality}
          onDeleteQuality={handleDeleteQuality}
        />
      </div>
      <Button
        disabled={!formValid || createInProgress}
        inProgress={createInProgress}
        onClick={handleCreateClicked}
      >
        Aanmaken
      </Button>
    </Container>
  );
};

export default CreateQuestionnaire;