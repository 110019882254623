import * as TWEEN from '@tweenjs/tween.js';
import { useEffect } from 'react';

export const TweenUpdate = () => {
  useEffect(() => {
    let handle;

    const update = () => {
      handle = requestAnimationFrame(update);

      TWEEN.update();
    }

    update();

    return () => {
      cancelAnimationFrame(handle);
    };
  }, []);
  return null;
}

export default TweenUpdate;