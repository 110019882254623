import { Quality } from "@hulder/client-common/model";
import { VoteInput } from "@hulder/hulder-spec-client";
import axios from "axios"

export const loadQuestionnaire = async (questionnaireId: string) => {
  return await axios.get(`/api/questionnaires/${questionnaireId}`);
}

export const loadResults = async (questionnaireId: string, secret: string) => {
  return await axios.get(`/api/questionnaires/${questionnaireId}/votes`, {
    headers: {
      'X-Secret': secret
    }
  });
}

export const submitVotes = async (
  questionnaireId: string,
  user: { name: string },
  votes: VoteInput[]
) => {
  return await axios.post(`/api/questionnaires/${questionnaireId}/vote`, {
    user,
    votes,
  });
};

type CreateQuestionnaireInput = {
  title?: string;
  secret: string;
  qualities: Quality[];
};
export const createQuestionnaire = async (input: CreateQuestionnaireInput) => {
  return await axios.post(`/api/questionnaires`, {
    questionnaire: input,
  });
}