import React, { ComponentProps } from "react";
import styles from "./ProgressBar.module.scss";

export interface ProgressBarProp {
    progression: Number,
    total: Number
}

export const ProgressBar: React.FC<ProgressBarProp & ComponentProps<'div'>> = ({ progression, total }) => {
    return <div className={[styles.container].join(' ')}>
        <div className={styles.progression} style={{ width: `calc(100% / ${total} * ${progression})` }}>&nbsp;</div>
    </div>
}

export default ProgressBar;