import React, { ComponentProps, forwardRef } from "react";
import styles from "./Button.module.scss";
import IndeterminateCircularProgress from "./IndeterminateCircularProgress";

export type ButtonProps = {
  color?: "red" | "grey";
  inProgress?: boolean;
} & ComponentProps<'button'>;

export default forwardRef<
  HTMLButtonElement,
  ButtonProps
>(({ color, inProgress, ...buttonProps }, ref) => {
  return (
    <button
      type="button"
      ref={ref}
      {...buttonProps}
      className={[
        styles.button,
        color == "grey" && styles['color-grey'],
        buttonProps.className,
      ].join(" ")}
    >
      {inProgress ? (
        <IndeterminateCircularProgress
          className={styles["progress"]}
          size={24}
          stroke="white"
        />
      ) : (
        buttonProps.children
      )}
    </button>
  );
});
