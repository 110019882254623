import { useEffect, useState } from "react";
import { toDataURL } from 'qrcode';

export const useQRDataUri = (data: string) => {
  const [uri, setUri] = useState<string|null>(null);

  useEffect(() => {
    if (!data) return;

    toDataURL(data, { type: "image/png" }).then((uri) => setUri(uri));
  }, [data]);

  return uri;
}