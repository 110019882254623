import { ComponentProps } from 'react';
import styles from './Container.module.scss';

export const Container: React.FC<ComponentProps<'div'>> = (props) => {
  const classes = [styles["container"], styles["md"], props.className].filter(
    (x) => x
  );

  return <div {...props} className={classes.join(' ')} />;
};

export default Container;