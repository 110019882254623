import { ThankYou } from "@hulder/client-common/components/ThankYou";
import Container from "../components/Container";
import styles from './VotesSubmitted.module.scss';

export const VotesSubmitted: React.FC = () => {
  return (
    <Container className={styles['container']}>
      <ThankYou />
    </Container>
  );
};

export default VotesSubmitted;
