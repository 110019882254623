import Container from "../components/Container";
import Text from "../components/Text";
import Title from "../components/Title";
import Button from '@hulder/client-common/components/Button';
import styles from "./Index.module.scss";
import { useHistory } from "react-router";
import { useAppSelector } from "../redux";

export const Index = () => {
  const history = useHistory();
  const questionnaire = useAppSelector(state => state.questionnaire);

  return (
    <Container className={styles["container"]}>
      <Title>Hulder Demo</Title>
      <Text>Welkom bij de Hulder demo!</Text>
      {questionnaire !== null ? (
        <Button onClick={() => history.push("/resultaten")}>
          Resultaten bekijken
        </Button>
      ) : null}
      <Button onClick={() => history.push("/aanmaken")}>
        Nieuwe waarderingslijst maken
      </Button>
    </Container>
  );
};

export default Index;