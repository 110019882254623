import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@hulder/client-common/components/Button";
import { QRCode } from "@hulder/client-common/components/QRCode";
import { QuestionnaireSummary } from "@hulder/client-common/components/QuestionnaireSummary";
import { QuestionnaireVote } from "@hulder/client-common/model";
import { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as api from "../api";
import Container from "../components/Container";
import Text from "../components/Text";
import Title from "../components/Title";
import { useAppSelector } from "../redux";
import { useResultsChannel } from "../ws";
import styles from "./ViewResults.module.scss";

export const ViewResults = () => {
  const questionnaire = useAppSelector((state) => state.questionnaire);
  const secret = sessionStorage.getItem("questionnaire_secret");
  const { onResultsChanged } = useResultsChannel(questionnaire?.id!!, secret!!);
  const qualities = questionnaire?.qualities || [];
  const [votes, setVotes] = useState<
    [{ id: string; name: string }, QuestionnaireVote[]][] | null
  >(null);
  const [resultsLoading, setResultsLoading] = useState(false);

  const updateItems = useCallback(() => {
    if (!questionnaire || !secret) {
      return;
    }

    api
      .loadResults(questionnaire.id, secret)
      .then((r) => {
        setVotes(r.data);
        setResultsLoading(false);
      })
      .catch((e) => {
        console.error(e);

        if (e.response) {
          const response = e.response as Response;

          if (response.status === 404) {
            setNotFound(true);
          }
        }

        setResultsLoading(false);
      });
  }, [questionnaire, secret]);

  useEffect(() => {
    updateItems();
  }, [questionnaire, secret, updateItems]);

  const [notFound, setNotFound] = useState(false);

  if (!questionnaire) {
    return <Redirect to="/" />;
  }

  onResultsChanged(() => {
    updateItems();
  });

  const users = votes
    ? votes.map((v) => ({
        id: v[0].id,
        firstName: v[0].name,
      }))
    : [];

  const usersById = users.reduce((o, user) => ({ ...o, [user.id]: user }), {});
  const qualitiesById = qualities.reduce(
    (o, quality) => ({ ...o, [quality.id]: quality }),
    {}
  );

  const votesMapped = votes
    ? votes
        .map(([user, votes]) =>
          votes.map((v2) => ({
            user: { id: user.id, firstName: user.name },
            quality: qualities.find((q) => q.id === v2.qualityId),
            hasVote: v2.hasVote,
            remarks: v2.remarks,
          }))
        )
        .flat()
    : [];

  const voteUrl = `https://${window.location.host}/stemmen/${questionnaire.id}`;

  if (notFound) {
    return (
      <Container className={styles["container"]}>
        Waarderingslijst niet gevonden
      </Container>
    );
  }

  return (
    <Container className={styles["container"]}>
      <Title className={styles["title"]}>Resultaten van waarderingslijst</Title>
      <Text className={styles["qr-hint"]}>
        Scan deze QR-code om te stemmen:
      </Text>
      <QRCode data={voteUrl} />
      {localStorage.getItem("dev-mode") ? (
        <a href={`/stemmen/${questionnaire.id}`}>stemmen</a>
      ) : null}
      <QuestionnaireSummary
        users={usersById}
        qualities={qualitiesById}
        votes={votesMapped}
        questionnaire={questionnaire}
        containerClass={styles["results-container"]}
        tabs={["qualities"]}
      />
    </Container>
  );
};

export default ViewResults;
