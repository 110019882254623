import Button from "@hulder/client-common/components/Button";
import IndeterminateCircularProgress from "@hulder/client-common/components/IndeterminateCircularProgress";
import { TextField } from "@hulder/client-common/components/TextField";
import { Voting } from "@hulder/client-common/components/Voting";
import { Questionnaire } from "@hulder/client-common/model";
import { User, VoteInput } from "@hulder/hulder-spec-client";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as api from "../api";
import Container from "../components/Container";
import Text from "../components/Text";
import styles from "./Vote.module.scss";
import TopBar from "../components/TopBar";

export const Vote: React.FC = () => {
  const [page, setPage] = useState(0);
  const [name, setName] = useState("");
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const match = useRouteMatch<{ id: string }>();
  const history = useHistory();

  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(
    null
  );
  const user: User = {
    id: "demo-user",
    firstName: "Gebruiker",
  };

  useEffect(() => {
    axios
      .get(`/api/questionnaires/${match.params.id}`)
      .then((res) => {
        setQuestionnaire(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setFetchError("Deze QR-code is niet (meer) geldig.");
        } else {
          setFetchError(
            "Er heeft zich een onbekende fout voorgedaan. Probeer het later nog eens"
          );
        }
      });
  }, [match]);

  const handleSubmit = async (votes: VoteInput[]) => {
    try {
      setSubmitError(null);

      await api.submitVotes(match.params.id, { name }, votes);

      history.push("/bedankt");
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setSubmitError("Deze waarderingslijst bestaat niet meer");
      } else {
        setSubmitError("Er ging iets mis. Probeer het later nog eens.");
      }
    }
  };

  if (fetchError) {
    return (
      <Container className={styles["container"]}>
        <Text>{fetchError}</Text>
      </Container>
    );
  }

  return (
    <>
      <TopBar />
      <Container className={styles["container"]}>
        {page === 0 ? (
          <div className={styles["register-form"]}>
            <Text>Vul je naam in</Text>
            <TextField
              className={styles["register-form-name-field"]}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Button disabled={name.length === 0} onClick={(e) => setPage(1)}>
              Verder
            </Button>
          </div>
        ) : (
          <div className={styles["voting"]}>
            {!questionnaire ? (
              <IndeterminateCircularProgress />
            ) : (
              <>
                {submitError ? <Text>{submitError}</Text> : null}
                <Voting
                  questionnaire={questionnaire}
                  qualities={questionnaire.qualities!!}
                  user={user}
                  onSubmit={handleSubmit}
                />
              </>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default Vote;
