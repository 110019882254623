import React, { ComponentProps, forwardRef } from "react";
import styles from "./IconButton.module.scss";
import IndeterminateCircularProgress from "./IndeterminateCircularProgress";

export type IconButtonProps = ComponentProps<"button"> & {
  inProgress?: boolean;
  progressClass?: string;
  size?: number;
  transparent?: boolean;
};

export default forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ inProgress, size, transparent, progressClass, ...buttonProps }, ref) => {
    const classes = [
      styles["icon-button"],
      transparent ? styles["transparent"] : null,
      buttonProps.className,
    ].filter((x) => x);

    const style = size ? {
      width: size,
      height: size
    } : {};

    return (
      <button
        type="button"
        style={{ ...buttonProps.style, ...style }}
        ref={ref}
        {...buttonProps}
        className={classes.join(" ")}
      >
        {inProgress ? (
          <IndeterminateCircularProgress
            className={[styles["progress"], progressClass || null].join(" ")}
            size={24}
            stroke="white"
          />
        ) : (
          buttonProps.children
        )}
      </button>
    );
  }
);
