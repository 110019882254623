import styles from './TopBar.module.scss';
import logoSvg from '../res/logo.svg';

export const TopBar: React.FC = () => {
  return <div className={styles['topbar']}>
    <div className={styles['topbar-inner']}>
      <img className={styles['logo']} src={logoSvg} />
    </div>
  </div>;
}

export default TopBar;