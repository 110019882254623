import { Quality, Questionnaire, QuestionnaireVote, User } from "@hulder/hulder-spec-client";
import { entries, fromPairs, keys, values } from "lodash";
import React, { ComponentProps, useState } from "react";
import { Carousel, CarouselItem } from "@hulder/client-common/components/Carousel";
import styles from "./QuestionnaireSummary.module.scss";
import RadarChart from "./RadarChart";

const VoteCountBadge: React.FC<{ count: number, className?: string }> = ({ count, className }) => {
  return <div className={[styles['vote-count-badge'], className].join(' ')}>{ count }</div>;
}

const TabNav: React.FC = ({ children }) => {
  return <div className={styles["tab-nav"]}>{children}</div>;
};

const Tab: React.FC<{ active?: boolean } & ComponentProps<"button">> = ({
  active,
  ...btnProps
}) => {
  return (
    <button
      {...btnProps}
      className={[
        styles["tab"],
        active ? styles["active"] : null,
        btnProps.className
      ].join(" ")}
    />
  );
};

const CheckIcon: React.FC<ComponentProps<"svg">> = (props) => {
  return (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
    </svg>
  );
};

const ChevronDownIcon: React.FC<ComponentProps<"svg">> = (props) => {
  return (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
    </svg>
  );
};

const ChevronUpIcon: React.FC<ComponentProps<"svg">> = (props) => {
  return (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
    </svg>
  );
};

const VoteDetailRemarks: React.FC<{ remarks?: string }> = ({ remarks }) => {
  if (!remarks) return null;

  return <span className={styles["vote-detail-remarks"]}>{remarks}</span>;
};

const VoteCheck: React.FC<{ checked: boolean }> = ({ checked }) => {
  return (
    <CheckIcon
      className={[
        styles["vote-check"],
        checked ? styles["checked"] : null
      ].join(" ")}
    />
  );
};

const ChartQualityCarouselItem: React.FC<{ quality: Quality }> = ({
  quality,
}) => {
  return (
    <CarouselItem className={styles["chart-quality-carousel-item"]}>
      <span>{quality.description}</span>
    </CarouselItem>
  );
};

const ChartQualitySelector: React.FC<{
  qualities: Record<string, Quality>;
  selectedId?: string | null;
  onChangeIndex?: (index: number) => void;
}> = ({ qualities, selectedId = null, onChangeIndex }) => {
  const selectedIndex = selectedId
    ? Math.max(0, keys(qualities).indexOf(selectedId))
    : 0;

  return (
    <Carousel className={styles['chart-quality-carousel']} arrowClass={styles['chart-quality-carousel-arrow']} index={selectedIndex} onChangeIndex={onChangeIndex}>
      {values(qualities).map((q) => (
        <ChartQualityCarouselItem key={q.id} quality={q} />
      ))}
    </Carousel>
  );
};

interface VoteQualityDetailProps {
  vote: QuestionnaireVote;
  highlight: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const VoteQualityDetail: React.FC<VoteQualityDetailProps> = ({
  vote,
  highlight,
  onMouseOver,
  onMouseOut
}) => {
  if (vote.hasVote == null || !vote.quality) return null;

  return (
    <div
      className={[
        styles["vote-quality-detail"],
        highlight ? styles["highlight"] : null
      ].join(" ")}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div>
        <VoteCheck checked={vote.hasVote || false} />
        <span>{vote.quality.description}</span>
      </div>
      <VoteDetailRemarks remarks={vote.remarks || ''} />
    </div>
  );
};

interface VoteParticipantDetailProps {
  vote: QuestionnaireVote;
  highlight: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const VoteParticipantDetail: React.FC<VoteParticipantDetailProps> = ({
  vote,
  highlight,
  onMouseOver,
  onMouseOut
}) => {
  if (!vote.user || vote.hasVote == null) return null;

  return (
    <div
      className={[
        styles["vote-participant-detail"],
        highlight ? styles["highlight"] : null
      ].join(" ")}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div>
        <VoteCheck checked={vote.hasVote} />
        <span>{[vote.user.firstName, vote.user.lastName].join(" ")}</span>
      </div>
      <VoteDetailRemarks remarks={vote.remarks || ''} />
    </div>
  );
};

type QuestionnaireSummaryListItemProps = {
  expanded?: boolean;
  highlight?: string | null;
  onToggle?: () => void;
  onMouseOver?: (id?: string) => void;
  onMouseOut?: (id?: string) => void;
} & (
    | {
      by: "participants";
      participant: User;
      votes: QuestionnaireVote[];
    }
    | { by: "qualities"; quality: Quality; votes: QuestionnaireVote[] }
  );

const QuestionnaireSummaryListItem: React.FC<QuestionnaireSummaryListItemProps> = (
  props
) => {
  const {
    votes,
    expanded,
    onToggle,
    highlight,
    onMouseOver,
    onMouseOut
  } = props;

  const participantName =
    props.by === "participants"
      ? [props.participant.firstName, props.participant.lastName].join(" ")
      : null;

  const refFnDetailList = (node: HTMLUListElement) => {
    if (!node) return;

    node.style.setProperty("--max-height", `${node.scrollHeight + 24}px`);
  };

  const handleMouseOver = (e: React.MouseEvent) => {
    if (props.by === "qualities") {
      onMouseOver?.apply(null, [props.quality.id]);
    }
  };
  const handleMouseOut = (e: React.MouseEvent) => {
    if (props.by === "qualities") {
      onMouseOut?.apply(null, [props.quality.id]);
    }
  };

  return (
    <li
      className={[
        styles["questionnaire-summary-list-item"],
        expanded ? styles["expanded"] : null,
        props.by === "qualities" && props.quality.id === highlight
          ? styles["highlight"]
          : null,
      ].join(" ")}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={styles["header"]} onClick={() => onToggle?.call(null)}>
        <span>
          {props.by === "participants"
            ? participantName
            : props.quality.description}
        </span>
        {votes.filter((v) => v.hasVote).length > 0 ? (
          <VoteCountBadge count={votes.filter((v) => v.hasVote).length} />
        ) : null}
        <div className={styles["actions"]}>
          {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>
      <div className={styles["contents"]}>
        <ul ref={refFnDetailList} className={styles["detail-list"]}>
          {votes.length === 0 ? <NoVotes /> : null}
          {votes.map((v) => {
            if (!v.user || !v.quality) return null;

            return props.by === "participants" ? (
              <VoteQualityDetail
                key={v.quality.id}
                vote={v}
                highlight={v.quality.id === highlight}
                onMouseOver={() => onMouseOver?.apply(null, [v.quality?.id])}
                onMouseOut={() => onMouseOut?.apply(null, [v.quality?.id])}
              />
            ) : (
              <VoteParticipantDetail
                key={v.user.id}
                vote={v}
                highlight={v.user.id === highlight}
                onMouseOver={() => onMouseOver?.apply(null, [v.user?.id])}
                onMouseOut={() => onMouseOut?.apply(null, [v.user?.id])}
              />
            );
          })}
        </ul>
      </div>
    </li>
  );
};

const NoVotes: React.FC = () => {
  return <span>Geen resultaten</span>;
}

export interface QuestionnaireSummaryListProps {
  votes: QuestionnaireVote[];
  qualities: Record<string, Quality>;
  participants: Record<string, User>;
  viewBy: "participants" | "qualities";
  highlight?: string | null;
  onMouseOver?: (id?: string) => void;
  onMouseOut?: (id?: string) => void;
}

export const QuestionnaireSummaryList: React.FC<
  QuestionnaireSummaryListProps
> = ({
  votes,
  participants,
  qualities,
  viewBy,
  highlight = null,
  onMouseOver,
  onMouseOut,
}) => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const votesByParticipant: Record<string, QuestionnaireVote[]> = Object.values(
    participants
  ).reduce(
    (o, p) => ({ ...o, [p.id]: votes.filter((v) => v?.user?.id === p.id) }),
    {}
  );

  const votesByQuality: Record<string, QuestionnaireVote[]> = Object.values(
    qualities
  ).reduce(
    (o, q) => ({ ...o, [q.id]: votes.filter((v) => v?.quality?.id === q.id) }),
    {}
  );

  const handleToggle = (id: string) => () => {
    setExpandedItem(expandedItem === id ? null : id);
  };

  return (
    <ul className={styles["questionnaire-summary-list"]}>
      {viewBy === "participants"
        ? entries(votesByParticipant).map(([k, votes]) => (
            <QuestionnaireSummaryListItem
              key={k}
              by="participants"
              participant={participants[k]}
              votes={votes}
              onToggle={handleToggle(k)}
              expanded={expandedItem === k}
              highlight={highlight}
              onMouseOver={(id) => onMouseOver?.apply(null, [id])}
              onMouseOut={(id) => onMouseOut?.apply(null, [id])}
            />
          ))
        : entries(votesByQuality).map(([k, votes]) => (
            <QuestionnaireSummaryListItem
              key={k}
              by="qualities"
              quality={qualities[k]}
              votes={votes}
              onToggle={handleToggle(k)}
              expanded={expandedItem === k}
              highlight={highlight}
              onMouseOver={(id) => onMouseOver?.apply(null, [id])}
              onMouseOut={(id) => onMouseOut?.apply(null, [id])}
            />
          ))}
    </ul>
  );
};

interface VoteCountSummaryProps {
  qualityVoteCount: number;
  participantCount: number;
}
const VoteCountSummary: React.FC<VoteCountSummaryProps> = ({ qualityVoteCount, participantCount }) => {
  return <div className={styles['chart-vote-count-summary']}>
    <span>{ qualityVoteCount }</span>
    <span>/</span>
    <span>{ participantCount }</span>
  </div>
}

type Tab = "participants" | "qualities" | "chart";

export interface QuestionnaireSummaryProps {
  questionnaire: Questionnaire;
  users: Record<string, User>;
  qualities: Record<string, Quality>;
  votes: QuestionnaireVote[];
  containerClass?: string;
  tabs?: Tab[];
}

export const QuestionnaireSummary: React.FC<QuestionnaireSummaryProps> = ({
  questionnaire,
  users,
  qualities,
  votes,
  containerClass,
  tabs = ['participants', 'qualities', 'chart']
}) => {
  const [viewBy, setViewBy] = useState<"participants" | "qualities" | "chart">(
    tabs[0]
  );

  const [qualityHighlight, setQualityHighlight] = useState<string | null>(keys(qualities)[0]);

  const votesByQuality = fromPairs(
    values(qualities).map((q) => [
      q.id,
      votes.filter((v) => v?.quality?.id === q.id)
    ])
  );

  const radarChartData = entries(votesByQuality).map(([id, votes]) => ({
    quality: qualities[id],
    count: votes.filter(v => v.hasVote).length
  }));

  const participants = [...new Set(votes.map((v) => v.user))];

  const currentVoteCount =
    qualityHighlight !== null
      ? votesByQuality[qualityHighlight].filter((vote) => vote.hasVote).length
      : 0;
  const participantCount = participants.length;

  return (
    <>
      <div className={`${styles["questionnaire-summary"]} ${containerClass || ''}`}>
        <div className={styles["questionnaire-summary-list-container"]}>
          <TabNav>
            { tabs.includes('participants') ? <Tab
              active={viewBy === "participants"}
              onClick={(e) => setViewBy("participants")}
            >
              Deelnemers
            </Tab> : null }
            { tabs.includes('qualities') ? <Tab
              active={viewBy === "qualities"}
              onClick={(e) => setViewBy("qualities")}
            >
              Waarderingen
            </Tab> : null }
            { tabs.includes('chart') ? <Tab
              active={viewBy === "chart"}
              onClick={(e) => setViewBy("chart")}
            >
              Grafiek
            </Tab> : null }
          </TabNav>
          {viewBy === "participants" || viewBy === "qualities" ? (
            <QuestionnaireSummaryList
              viewBy={viewBy}
              votes={votes}
              qualities={qualities}
              participants={participants.reduce(
                (o, p) => (p ? { ...o, [p.id]: p } : o),
                {}
              )}
            />
          ) : null}
          {viewBy === "chart" ? (
            <div className={styles["chart-container"]}>
              <ChartQualitySelector
                qualities={qualities}
                selectedId={qualityHighlight}
                onChangeIndex={(index: number) => {
                  if (index >= 0) {
                    setQualityHighlight(keys(qualities)[index]);
                  } else {
                    setQualityHighlight(null);
                  }
                }}
              />
              <VoteCountSummary qualityVoteCount={currentVoteCount} participantCount={participantCount} />
              <RadarChart
                data={radarChartData}
                highlight={qualityHighlight}
                onSelect={id => setQualityHighlight(id)}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};