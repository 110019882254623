import { useWebSocket } from "../context/websocket";

export type ChannelState = 'pending' | 'subscribed' | 'closed';

type GenericEventListener<E> = (event: E) => void;
type GenericEventListenerObject<E> = {handleEvent(event: E): void};
type GenericEventListenerOrObject<E> = GenericEventListener<E> | GenericEventListenerObject<E>;

export class SubscribedEvent extends Event {
  constructor(
    readonly channel: Channel,
  ) {
    super("subscribed");
  }
}

export class ChannelMessageEvent extends Event {
  constructor(readonly channel: Channel, readonly data: any) {
    super('message');
  }
}

export class Channel extends EventTarget {
  state: ChannelState = "pending";

  handleMessage = (e: MessageEvent) => {
    const [type, ...payload] = JSON.parse(e.data);

    switch (type) {
      case "subscribed": {
        const [channel] = payload;

        if (channel === this.name) {
          this.state = "subscribed";

          this.dispatchEvent(new SubscribedEvent(this));
        }
        break;
      }
      case "message": {
        const [channel, data] = payload;

        if (channel === this.name) {
          this.dispatchEvent(new ChannelMessageEvent(this, data));
        }

        break;
      }
    }
  };

  constructor(
    readonly ws: WebSocket,
    readonly name: string,
    readonly attributes?: Record<string, any>
  ) {
    super();
    ws.addEventListener("message", this.handleMessage);
  }

  addEventListener(
    type: "subscribed",
    callback: GenericEventListenerOrObject<SubscribedEvent> | null,
    options?: boolean | AddEventListenerOptions
  ): void;
  addEventListener(
    type: "message",
    callback: GenericEventListenerOrObject<ChannelMessageEvent> | null,
    options?: boolean | AddEventListenerOptions
  ): void;
  addEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    options?: boolean | AddEventListenerOptions
  ): void {
    super.addEventListener(type, callback, options);
  }

  subscribe() {
    this.state = 'pending';
    this.ws.send(JSON.stringify(["subscribe", this.name, this.attributes]));
  }

  unsubscribe() {
    this.state = "closed";
    this.ws.removeEventListener("message", this.handleMessage);
  }
}

export const useResultsChannel = (
  questionnaireId: string,
  secret: string
): { onResultsChanged(f: () => void): void } => {
  const ws = useWebSocket();

  const channel = new Channel(ws, `questionnaire/${questionnaireId}/results`, {
    secret,
  });

  const handleOpen = () => {
    console.log('websocket opened');
    channel.subscribe();
  }

  const handleClose = () => {
    console.log("websocket closed");
    channel.unsubscribe();
  }

  const handleError = (e: Event) => {
    console.error('websocket error', e);
  }

  ws.addEventListener('open', handleOpen);
  ws.addEventListener('close', handleClose);
  ws.addEventListener('error', handleError);

  return {
    onResultsChanged(f: () => void) {
      channel.addEventListener("message", (e: ChannelMessageEvent) => {
        f();
      });
    },
  };
};
