import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Date: any;
};


export type AuthenticateInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  createSession?: Maybe<Scalars['Boolean']>;
};

export type AuthenticateResult = {
  __typename?: 'AuthenticateResult';
  user: User;
  token?: Maybe<Scalars['ID']>;
};

export type BaseEntity = {
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CastVotesInput = {
  votes: Array<VoteInput>;
};

export type Contract = BaseEntity & {
  __typename?: 'Contract';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  seats?: Maybe<Scalars['Int']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationContactName?: Maybe<Scalars['String']>;
  organizationContactInfo?: Maybe<Scalars['String']>;
};

export type CreateGroupInput = {
  name: Scalars['String'];
};

export type CreateQualityInput = {
  description?: Maybe<Scalars['String']>;
};

export type CreateQuestionnaireInput = {
  qualities: Array<Scalars['ID']>;
  participants?: Maybe<Array<ParticipantInput>>;
  title: Scalars['String'];
  groupId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['ID']>>;
  groups?: Maybe<Array<Scalars['ID']>>;
};


export type Group = BaseEntity & {
  __typename?: 'Group';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
  userCount?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticate?: Maybe<AuthenticateResult>;
  deauthenticate?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<User>;
  createQuestionnaire?: Maybe<Questionnaire>;
  createQuality?: Maybe<Quality>;
  updateUser?: Maybe<User>;
  deleteUser?: Maybe<User>;
  deleteQuestionnaire?: Maybe<Questionnaire>;
  deleteQuality?: Maybe<Quality>;
  createGroup?: Maybe<Group>;
  updateGroup?: Maybe<Group>;
  deleteGroup?: Maybe<Group>;
  updateContract?: Maybe<Contract>;
  openQuestionnaire?: Maybe<Questionnaire>;
  completeQuestionnaire?: Maybe<Questionnaire>;
  inviteParticipants?: Maybe<Array<Maybe<User>>>;
  castVotes?: Maybe<Array<QuestionnaireVote>>;
  requestPasswordReset?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  reportError?: Maybe<Scalars['Boolean']>;
  registerWithToken?: Maybe<RegisterWithTokenResult>;
};


export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateQuestionnaireArgs = {
  input: CreateQuestionnaireInput;
};


export type MutationCreateQualityArgs = {
  input: CreateQualityInput;
};


export type MutationUpdateUserArgs = {
  userId: Scalars['ID'];
  input: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteQuestionnaireArgs = {
  questionnaireId: Scalars['ID'];
};


export type MutationDeleteQualityArgs = {
  qualityId: Scalars['ID'];
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['ID'];
  input: UpdateGroupInput;
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['ID'];
  newGroupId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateContractArgs = {
  input: UpdateContractInput;
};


export type MutationOpenQuestionnaireArgs = {
  questionnaireId: Scalars['ID'];
};


export type MutationCompleteQuestionnaireArgs = {
  questionnaireId: Scalars['ID'];
};


export type MutationInviteParticipantsArgs = {
  questionnaireId: Scalars['ID'];
  participants: Array<ParticipantInput>;
};


export type MutationCastVotesArgs = {
  questionnaireId: Scalars['ID'];
  input: CastVotesInput;
};


export type MutationRequestPasswordResetArgs = {
  usernameOrEmail: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  token?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationReportErrorArgs = {
  error: Scalars['String'];
};


export type MutationRegisterWithTokenArgs = {
  input: RegisterWithTokenInput;
  createSession?: Maybe<Scalars['Boolean']>;
};

export type PaginationInput = {
  from?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
};

export type PaginationItem = User;

export type PaginationResult = {
  __typename?: 'PaginationResult';
  items: Array<PaginationItem>;
  from: Scalars['Int'];
  rows: Scalars['Int'];
};

export type ParticipantInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Quality = BaseEntity & {
  __typename?: 'Quality';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  questionnaireCount?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  users?: Maybe<PaginationResult>;
  userCount?: Maybe<Scalars['Int']>;
  groups?: Maybe<Array<Group>>;
  questionnaires?: Maybe<Array<Questionnaire>>;
  qualities?: Maybe<Array<Quality>>;
  votes?: Maybe<Array<Vote>>;
  contracts?: Maybe<Array<Contract>>;
  user?: Maybe<User>;
  questionnaire?: Maybe<Questionnaire>;
  quality?: Maybe<Quality>;
  vote?: Maybe<Vote>;
  group?: Maybe<Group>;
  contract?: Maybe<Contract>;
  seatsInUse?: Maybe<Scalars['Int']>;
  verifyRegistrationToken?: Maybe<Questionnaire>;
};


export type QueryUsersArgs = {
  filter?: Maybe<UserFilterInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryUserCountArgs = {
  filter?: Maybe<UserFilterInput>;
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};


export type QueryQuestionnaireArgs = {
  questionnaireId: Scalars['ID'];
};


export type QueryQualityArgs = {
  qualityId: Scalars['ID'];
};


export type QueryVoteArgs = {
  voteId: Scalars['ID'];
};


export type QueryGroupArgs = {
  groupId: Scalars['ID'];
};


export type QueryContractArgs = {
  contractId: Scalars['ID'];
};


export type QueryVerifyRegistrationTokenArgs = {
  token: Scalars['String'];
};

export type Questionnaire = BaseEntity & {
  __typename?: 'Questionnaire';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  qualities?: Maybe<Array<Quality>>;
  votes?: Maybe<Array<Vote>>;
  status?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<User>>;
  group?: Maybe<Group>;
  hasVoteFrom?: Maybe<Scalars['Boolean']>;
  registrationToken?: Maybe<Scalars['String']>;
  registrationUrl?: Maybe<Scalars['String']>;
};


export type QuestionnaireHasVoteFromArgs = {
  userId: Scalars['ID'];
};

export type QuestionnaireVote = {
  __typename?: 'QuestionnaireVote';
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  questionnaire?: Maybe<Questionnaire>;
  quality?: Maybe<Quality>;
  hasVote?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
};

export type RegisterWithTokenInput = {
  token: Scalars['String'];
  name: Scalars['String'];
};

export type RegisterWithTokenResult = {
  __typename?: 'RegisterWithTokenResult';
  user: User;
  token: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  capabilities?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateContractInput = {
  organizationName?: Maybe<Scalars['String']>;
  organizationContactName?: Maybe<Scalars['String']>;
  organizationContactInfo?: Maybe<Scalars['String']>;
};

export type UpdateGroupInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['ID']>>;
  groups?: Maybe<Array<Scalars['ID']>>;
};

export type User = BaseEntity & {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  qualities?: Maybe<Array<Quality>>;
  questionnaires?: Maybe<Array<Questionnaire>>;
  roles?: Maybe<Array<Role>>;
  votes?: Maybe<Array<Vote>>;
  sessionScope?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Group>>;
  hasVotedOn?: Maybe<Scalars['Boolean']>;
};


export type UserHasVotedOnArgs = {
  questionnaireId: Scalars['ID'];
};

export type UserFilterInput = {
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Scalars['ID']>>;
  roles?: Maybe<Array<Scalars['ID']>>;
};

export type UserQuestionnaire = {
  __typename?: 'UserQuestionnaire';
  user: User;
  questionnaire: Questionnaire;
};

export type Vote = {
  __typename?: 'Vote';
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  remarks?: Maybe<Scalars['String']>;
  hasVote: Scalars['Boolean'];
  questionnaire?: Maybe<Questionnaire>;
  user?: Maybe<User>;
  quality?: Maybe<Quality>;
};

export type VoteInput = {
  qualityId: Scalars['ID'];
  remarks?: Maybe<Scalars['String']>;
  hasVote?: Maybe<Scalars['Boolean']>;
};

export type ContractsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContractsQuery = (
  { __typename?: 'Query' }
  & { contracts?: Maybe<Array<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'seats' | 'organizationName' | 'organizationContactInfo' | 'organizationContactName'>
  )>> }
);

export type ContractQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;


export type ContractQuery = (
  { __typename?: 'Query' }
  & { contract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'seats' | 'organizationName' | 'organizationContactInfo' | 'organizationContactName'>
  )> }
);

export type SeatsInUseQueryVariables = Exact<{ [key: string]: never; }>;


export type SeatsInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'seatsInUse'>
);

export type GroupQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type GroupQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )>> }
);

export type GroupWithUserCountQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type GroupWithUserCountQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'userCount'>
  )> }
);

export type GroupsWithUserCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsWithUserCountQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'userCount'>
  )>> }
);

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type UpdateGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  input: UpdateGroupInput;
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  newGroupId?: Maybe<Scalars['ID']>;
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type MyQualitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyQualitiesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { qualities?: Maybe<Array<(
      { __typename?: 'Quality' }
      & Pick<Quality, 'id' | 'createdAt' | 'updatedAt' | 'description' | 'questionnaireCount'>
    )>> }
  )> }
);

export type CreateQualityMutationVariables = Exact<{
  input: CreateQualityInput;
}>;


export type CreateQualityMutation = (
  { __typename?: 'Mutation' }
  & { createQuality?: Maybe<(
    { __typename?: 'Quality' }
    & Pick<Quality, 'id' | 'createdAt' | 'updatedAt' | 'description' | 'questionnaireCount'>
  )> }
);

export type DeleteQualityMutationVariables = Exact<{
  qualityId: Scalars['ID'];
}>;


export type DeleteQualityMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuality?: Maybe<(
    { __typename?: 'Quality' }
    & Pick<Quality, 'id'>
  )> }
);

export type QuestionnaireRegistrationUrlQueryVariables = Exact<{
  questionnaireId: Scalars['ID'];
}>;


export type QuestionnaireRegistrationUrlQuery = (
  { __typename?: 'Query' }
  & { questionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'registrationUrl'>
  )> }
);

export type MyQuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyQuestionnairesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { questionnaires?: Maybe<Array<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'description' | 'status'>
    )>> }
  )> }
);

export type QuestionnaireQueryVariables = Exact<{
  questionnaireId: Scalars['ID'];
}>;


export type QuestionnaireQuery = (
  { __typename?: 'Query' }
  & { questionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'status' | 'title' | 'description' | 'createdAt' | 'updatedAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName'>
    )> }
  )> }
);

export type QuestionnaireWithContextQueryVariables = Exact<{
  questionnaireId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type QuestionnaireWithContextQuery = (
  { __typename?: 'Query' }
  & { questionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'status' | 'title' | 'description' | 'createdAt' | 'updatedAt' | 'hasVoteFrom'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, qualities?: Maybe<Array<(
      { __typename?: 'Quality' }
      & Pick<Quality, 'id' | 'description'>
    )>>, participants?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'hasVotedOn'>
    )>>, votes?: Maybe<Array<(
      { __typename?: 'Vote' }
      & Pick<Vote, 'remarks' | 'hasVote'>
      & { quality?: Maybe<(
        { __typename?: 'Quality' }
        & Pick<Quality, 'id' | 'description'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'hasVotedOn'>
      )> }
    )>> }
  )> }
);

export type CastVotesMutationVariables = Exact<{
  questionnaireId: Scalars['ID'];
  input: CastVotesInput;
}>;


export type CastVotesMutation = (
  { __typename?: 'Mutation' }
  & { castVotes?: Maybe<Array<(
    { __typename?: 'QuestionnaireVote' }
    & Pick<QuestionnaireVote, 'hasVote' | 'remarks'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, questionnaire?: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id'>
    )>, quality?: Maybe<(
      { __typename?: 'Quality' }
      & Pick<Quality, 'id' | 'description'>
    )> }
  )>> }
);

export type CreateQuestionnaireMutationVariables = Exact<{
  input: CreateQuestionnaireInput;
}>;


export type CreateQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { createQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'title' | 'status' | 'description' | 'createdAt' | 'updatedAt'>
    & { qualities?: Maybe<Array<(
      { __typename?: 'Quality' }
      & Pick<Quality, 'id' | 'questionnaireCount'>
    )>> }
  )> }
);

export type DeleteQuestionnaireMutationVariables = Exact<{
  questionnaireId: Scalars['ID'];
}>;


export type DeleteQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'title' | 'status' | 'description' | 'createdAt' | 'updatedAt'>
    & { qualities?: Maybe<Array<(
      { __typename?: 'Quality' }
      & Pick<Quality, 'id' | 'questionnaireCount'>
    )>> }
  )> }
);

export type OpenQuestionnaireMutationVariables = Exact<{
  questionnaireId: Scalars['ID'];
}>;


export type OpenQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { openQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'status'>
  )> }
);

export type CompleteQuestionnaireMutationVariables = Exact<{
  questionnaireId: Scalars['ID'];
}>;


export type CompleteQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { completeQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'status'>
  )> }
);

export type InviteParticipantsMutationVariables = Exact<{
  questionnaireId: Scalars['ID'];
  participants: Array<ParticipantInput> | ParticipantInput;
}>;


export type InviteParticipantsMutation = (
  { __typename?: 'Mutation' }
  & { inviteParticipants?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )>>> }
);

export type VerifyRegistrationTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyRegistrationTokenQuery = (
  { __typename?: 'Query' }
  & { verifyRegistrationToken?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'description' | 'title' | 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName'>
    )> }
  )> }
);

export type RegisterWithTokenMutationVariables = Exact<{
  input: RegisterWithTokenInput;
  createSession?: Maybe<Scalars['Boolean']>;
}>;


export type RegisterWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { registerWithToken?: Maybe<(
    { __typename?: 'RegisterWithTokenResult' }
    & Pick<RegisterWithTokenResult, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'fullName'>
    ) }
  )> }
);

export type ReportErrorMutationVariables = Exact<{
  error: Scalars['String'];
}>;


export type ReportErrorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportError'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'fullName' | 'sessionScope'>
    & { roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'capabilities'>
    )>>, groups?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>> }
  )> }
);

export type UsersQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'PaginationResult' }
    & Pick<PaginationResult, 'from' | 'rows'>
    & { items: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'fullName' | 'email'>
      & { roles?: Maybe<Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'capabilities'>
      )>>, groups?: Maybe<Array<(
        { __typename?: 'Group' }
        & Pick<Group, 'id' | 'name'>
      )>> }
    )> }
  )> }
);

export type UserCountQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
}>;


export type UserCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userCount'>
);

export type GetUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'email' | 'firstName' | 'lastName' | 'fullName'>
    & { roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'capabilities'>
    )>>, groups?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>> }
  )> }
);

export type AuthenticateMutationVariables = Exact<{
  input: AuthenticateInput;
}>;


export type AuthenticateMutation = (
  { __typename?: 'Mutation' }
  & { authenticate?: Maybe<(
    { __typename?: 'AuthenticateResult' }
    & Pick<AuthenticateResult, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'fullName' | 'sessionScope'>
      & { roles?: Maybe<Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'capabilities'>
      )>>, groups?: Maybe<Array<(
        { __typename?: 'Group' }
        & Pick<Group, 'id' | 'name'>
      )>> }
    ) }
  )> }
);

export type DeauthenticateMutationVariables = Exact<{ [key: string]: never; }>;


export type DeauthenticateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deauthenticate'>
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'email'>
    & { roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'capabilities'>
    )>>, groups?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'email'>
    & { roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'capabilities'>
    )>>, groups?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>> }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'email'>
    & { roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'capabilities'>
    )>>, groups?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>> }
  )> }
);

export type RequestPasswordResetMutationVariables = Exact<{
  usernameOrEmail: Scalars['String'];
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestPasswordReset'>
);

export type ResetPasswordMutationVariables = Exact<{
  token?: Maybe<Scalars['String']>;
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);


export const ContractsDocument = gql`
    query Contracts {
  contracts {
    id
    seats
    organizationName
    organizationContactInfo
    organizationContactName
  }
}
    `;

/**
 * __useContractsQuery__
 *
 * To run a query within a React component, call `useContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractsQuery(baseOptions?: Apollo.QueryHookOptions<ContractsQuery, ContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
      }
export function useContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractsQuery, ContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
        }
export type ContractsQueryHookResult = ReturnType<typeof useContractsQuery>;
export type ContractsLazyQueryHookResult = ReturnType<typeof useContractsLazyQuery>;
export type ContractsQueryResult = Apollo.QueryResult<ContractsQuery, ContractsQueryVariables>;
export const ContractDocument = gql`
    query Contract($contractId: ID!) {
  contract(contractId: $contractId) {
    id
    seats
    organizationName
    organizationContactInfo
    organizationContactName
  }
}
    `;

/**
 * __useContractQuery__
 *
 * To run a query within a React component, call `useContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useContractQuery(baseOptions: Apollo.QueryHookOptions<ContractQuery, ContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
      }
export function useContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractQuery, ContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
        }
export type ContractQueryHookResult = ReturnType<typeof useContractQuery>;
export type ContractLazyQueryHookResult = ReturnType<typeof useContractLazyQuery>;
export type ContractQueryResult = Apollo.QueryResult<ContractQuery, ContractQueryVariables>;
export const SeatsInUseDocument = gql`
    query SeatsInUse {
  seatsInUse
}
    `;

/**
 * __useSeatsInUseQuery__
 *
 * To run a query within a React component, call `useSeatsInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeatsInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeatsInUseQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeatsInUseQuery(baseOptions?: Apollo.QueryHookOptions<SeatsInUseQuery, SeatsInUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeatsInUseQuery, SeatsInUseQueryVariables>(SeatsInUseDocument, options);
      }
export function useSeatsInUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeatsInUseQuery, SeatsInUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeatsInUseQuery, SeatsInUseQueryVariables>(SeatsInUseDocument, options);
        }
export type SeatsInUseQueryHookResult = ReturnType<typeof useSeatsInUseQuery>;
export type SeatsInUseLazyQueryHookResult = ReturnType<typeof useSeatsInUseLazyQuery>;
export type SeatsInUseQueryResult = Apollo.QueryResult<SeatsInUseQuery, SeatsInUseQueryVariables>;
export const GroupDocument = gql`
    query Group($groupId: ID!) {
  group(groupId: $groupId) {
    id
    name
  }
}
    `;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = Apollo.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupsDocument = gql`
    query Groups {
  groups {
    id
    name
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const GroupWithUserCountDocument = gql`
    query GroupWithUserCount($groupId: ID!) {
  group(groupId: $groupId) {
    id
    name
    userCount
  }
}
    `;

/**
 * __useGroupWithUserCountQuery__
 *
 * To run a query within a React component, call `useGroupWithUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupWithUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupWithUserCountQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupWithUserCountQuery(baseOptions: Apollo.QueryHookOptions<GroupWithUserCountQuery, GroupWithUserCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupWithUserCountQuery, GroupWithUserCountQueryVariables>(GroupWithUserCountDocument, options);
      }
export function useGroupWithUserCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupWithUserCountQuery, GroupWithUserCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupWithUserCountQuery, GroupWithUserCountQueryVariables>(GroupWithUserCountDocument, options);
        }
export type GroupWithUserCountQueryHookResult = ReturnType<typeof useGroupWithUserCountQuery>;
export type GroupWithUserCountLazyQueryHookResult = ReturnType<typeof useGroupWithUserCountLazyQuery>;
export type GroupWithUserCountQueryResult = Apollo.QueryResult<GroupWithUserCountQuery, GroupWithUserCountQueryVariables>;
export const GroupsWithUserCountDocument = gql`
    query GroupsWithUserCount {
  groups {
    id
    name
    userCount
  }
}
    `;

/**
 * __useGroupsWithUserCountQuery__
 *
 * To run a query within a React component, call `useGroupsWithUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsWithUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsWithUserCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsWithUserCountQuery(baseOptions?: Apollo.QueryHookOptions<GroupsWithUserCountQuery, GroupsWithUserCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsWithUserCountQuery, GroupsWithUserCountQueryVariables>(GroupsWithUserCountDocument, options);
      }
export function useGroupsWithUserCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsWithUserCountQuery, GroupsWithUserCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsWithUserCountQuery, GroupsWithUserCountQueryVariables>(GroupsWithUserCountDocument, options);
        }
export type GroupsWithUserCountQueryHookResult = ReturnType<typeof useGroupsWithUserCountQuery>;
export type GroupsWithUserCountLazyQueryHookResult = ReturnType<typeof useGroupsWithUserCountLazyQuery>;
export type GroupsWithUserCountQueryResult = Apollo.QueryResult<GroupsWithUserCountQuery, GroupsWithUserCountQueryVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($input: CreateGroupInput!) {
  createGroup(input: $input) {
    id
    name
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($groupId: ID!, $input: UpdateGroupInput!) {
  updateGroup(groupId: $groupId, input: $input) {
    id
    name
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($groupId: ID!, $newGroupId: ID) {
  deleteGroup(groupId: $groupId, newGroupId: $newGroupId) {
    id
    name
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      newGroupId: // value for 'newGroupId'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const MyQualitiesDocument = gql`
    query MyQualities {
  me {
    id
    qualities {
      id
      createdAt
      updatedAt
      description
      questionnaireCount
    }
  }
}
    `;

/**
 * __useMyQualitiesQuery__
 *
 * To run a query within a React component, call `useMyQualitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyQualitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyQualitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyQualitiesQuery(baseOptions?: Apollo.QueryHookOptions<MyQualitiesQuery, MyQualitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyQualitiesQuery, MyQualitiesQueryVariables>(MyQualitiesDocument, options);
      }
export function useMyQualitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyQualitiesQuery, MyQualitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyQualitiesQuery, MyQualitiesQueryVariables>(MyQualitiesDocument, options);
        }
export type MyQualitiesQueryHookResult = ReturnType<typeof useMyQualitiesQuery>;
export type MyQualitiesLazyQueryHookResult = ReturnType<typeof useMyQualitiesLazyQuery>;
export type MyQualitiesQueryResult = Apollo.QueryResult<MyQualitiesQuery, MyQualitiesQueryVariables>;
export const CreateQualityDocument = gql`
    mutation CreateQuality($input: CreateQualityInput!) {
  createQuality(input: $input) {
    id
    createdAt
    updatedAt
    description
    questionnaireCount
  }
}
    `;
export type CreateQualityMutationFn = Apollo.MutationFunction<CreateQualityMutation, CreateQualityMutationVariables>;

/**
 * __useCreateQualityMutation__
 *
 * To run a mutation, you first call `useCreateQualityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQualityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQualityMutation, { data, loading, error }] = useCreateQualityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQualityMutation(baseOptions?: Apollo.MutationHookOptions<CreateQualityMutation, CreateQualityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQualityMutation, CreateQualityMutationVariables>(CreateQualityDocument, options);
      }
export type CreateQualityMutationHookResult = ReturnType<typeof useCreateQualityMutation>;
export type CreateQualityMutationResult = Apollo.MutationResult<CreateQualityMutation>;
export type CreateQualityMutationOptions = Apollo.BaseMutationOptions<CreateQualityMutation, CreateQualityMutationVariables>;
export const DeleteQualityDocument = gql`
    mutation DeleteQuality($qualityId: ID!) {
  deleteQuality(qualityId: $qualityId) {
    id
  }
}
    `;
export type DeleteQualityMutationFn = Apollo.MutationFunction<DeleteQualityMutation, DeleteQualityMutationVariables>;

/**
 * __useDeleteQualityMutation__
 *
 * To run a mutation, you first call `useDeleteQualityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQualityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQualityMutation, { data, loading, error }] = useDeleteQualityMutation({
 *   variables: {
 *      qualityId: // value for 'qualityId'
 *   },
 * });
 */
export function useDeleteQualityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQualityMutation, DeleteQualityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQualityMutation, DeleteQualityMutationVariables>(DeleteQualityDocument, options);
      }
export type DeleteQualityMutationHookResult = ReturnType<typeof useDeleteQualityMutation>;
export type DeleteQualityMutationResult = Apollo.MutationResult<DeleteQualityMutation>;
export type DeleteQualityMutationOptions = Apollo.BaseMutationOptions<DeleteQualityMutation, DeleteQualityMutationVariables>;
export const QuestionnaireRegistrationUrlDocument = gql`
    query QuestionnaireRegistrationUrl($questionnaireId: ID!) {
  questionnaire(questionnaireId: $questionnaireId) {
    registrationUrl
  }
}
    `;

/**
 * __useQuestionnaireRegistrationUrlQuery__
 *
 * To run a query within a React component, call `useQuestionnaireRegistrationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireRegistrationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireRegistrationUrlQuery({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useQuestionnaireRegistrationUrlQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireRegistrationUrlQuery, QuestionnaireRegistrationUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireRegistrationUrlQuery, QuestionnaireRegistrationUrlQueryVariables>(QuestionnaireRegistrationUrlDocument, options);
      }
export function useQuestionnaireRegistrationUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireRegistrationUrlQuery, QuestionnaireRegistrationUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireRegistrationUrlQuery, QuestionnaireRegistrationUrlQueryVariables>(QuestionnaireRegistrationUrlDocument, options);
        }
export type QuestionnaireRegistrationUrlQueryHookResult = ReturnType<typeof useQuestionnaireRegistrationUrlQuery>;
export type QuestionnaireRegistrationUrlLazyQueryHookResult = ReturnType<typeof useQuestionnaireRegistrationUrlLazyQuery>;
export type QuestionnaireRegistrationUrlQueryResult = Apollo.QueryResult<QuestionnaireRegistrationUrlQuery, QuestionnaireRegistrationUrlQueryVariables>;
export const MyQuestionnairesDocument = gql`
    query MyQuestionnaires {
  me {
    id
    questionnaires {
      id
      createdAt
      updatedAt
      title
      description
      status
    }
  }
}
    `;

/**
 * __useMyQuestionnairesQuery__
 *
 * To run a query within a React component, call `useMyQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<MyQuestionnairesQuery, MyQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyQuestionnairesQuery, MyQuestionnairesQueryVariables>(MyQuestionnairesDocument, options);
      }
export function useMyQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyQuestionnairesQuery, MyQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyQuestionnairesQuery, MyQuestionnairesQueryVariables>(MyQuestionnairesDocument, options);
        }
export type MyQuestionnairesQueryHookResult = ReturnType<typeof useMyQuestionnairesQuery>;
export type MyQuestionnairesLazyQueryHookResult = ReturnType<typeof useMyQuestionnairesLazyQuery>;
export type MyQuestionnairesQueryResult = Apollo.QueryResult<MyQuestionnairesQuery, MyQuestionnairesQueryVariables>;
export const QuestionnaireDocument = gql`
    query Questionnaire($questionnaireId: ID!) {
  questionnaire(questionnaireId: $questionnaireId) {
    id
    user {
      id
      firstName
      lastName
      fullName
    }
    status
    title
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireQuery({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, options);
      }
export function useQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, options);
        }
export type QuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireQuery>;
export type QuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireLazyQuery>;
export type QuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireQuery, QuestionnaireQueryVariables>;
export const QuestionnaireWithContextDocument = gql`
    query QuestionnaireWithContext($questionnaireId: ID!, $userId: ID!) {
  questionnaire(questionnaireId: $questionnaireId) {
    id
    user {
      id
      firstName
      lastName
      fullName
    }
    status
    title
    description
    createdAt
    updatedAt
    hasVoteFrom(userId: $userId)
    group {
      id
      name
    }
    qualities {
      id
      description
    }
    participants {
      id
      firstName
      lastName
      hasVotedOn(questionnaireId: $questionnaireId)
    }
    votes {
      quality {
        id
        description
      }
      user {
        id
        firstName
        lastName
        hasVotedOn(questionnaireId: $questionnaireId)
      }
      remarks
      hasVote
    }
  }
}
    `;

/**
 * __useQuestionnaireWithContextQuery__
 *
 * To run a query within a React component, call `useQuestionnaireWithContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireWithContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireWithContextQuery({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useQuestionnaireWithContextQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireWithContextQuery, QuestionnaireWithContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireWithContextQuery, QuestionnaireWithContextQueryVariables>(QuestionnaireWithContextDocument, options);
      }
export function useQuestionnaireWithContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireWithContextQuery, QuestionnaireWithContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireWithContextQuery, QuestionnaireWithContextQueryVariables>(QuestionnaireWithContextDocument, options);
        }
export type QuestionnaireWithContextQueryHookResult = ReturnType<typeof useQuestionnaireWithContextQuery>;
export type QuestionnaireWithContextLazyQueryHookResult = ReturnType<typeof useQuestionnaireWithContextLazyQuery>;
export type QuestionnaireWithContextQueryResult = Apollo.QueryResult<QuestionnaireWithContextQuery, QuestionnaireWithContextQueryVariables>;
export const CastVotesDocument = gql`
    mutation CastVotes($questionnaireId: ID!, $input: CastVotesInput!) {
  castVotes(questionnaireId: $questionnaireId, input: $input) {
    user {
      id
    }
    questionnaire {
      id
    }
    quality {
      id
      description
    }
    hasVote
    remarks
  }
}
    `;
export type CastVotesMutationFn = Apollo.MutationFunction<CastVotesMutation, CastVotesMutationVariables>;

/**
 * __useCastVotesMutation__
 *
 * To run a mutation, you first call `useCastVotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCastVotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [castVotesMutation, { data, loading, error }] = useCastVotesMutation({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCastVotesMutation(baseOptions?: Apollo.MutationHookOptions<CastVotesMutation, CastVotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CastVotesMutation, CastVotesMutationVariables>(CastVotesDocument, options);
      }
export type CastVotesMutationHookResult = ReturnType<typeof useCastVotesMutation>;
export type CastVotesMutationResult = Apollo.MutationResult<CastVotesMutation>;
export type CastVotesMutationOptions = Apollo.BaseMutationOptions<CastVotesMutation, CastVotesMutationVariables>;
export const CreateQuestionnaireDocument = gql`
    mutation CreateQuestionnaire($input: CreateQuestionnaireInput!) {
  createQuestionnaire(input: $input) {
    id
    title
    status
    description
    createdAt
    updatedAt
    qualities {
      id
      questionnaireCount
    }
  }
}
    `;
export type CreateQuestionnaireMutationFn = Apollo.MutationFunction<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;

/**
 * __useCreateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCreateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionnaireMutation, { data, loading, error }] = useCreateQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>(CreateQuestionnaireDocument, options);
      }
export type CreateQuestionnaireMutationHookResult = ReturnType<typeof useCreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationResult = Apollo.MutationResult<CreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;
export const DeleteQuestionnaireDocument = gql`
    mutation DeleteQuestionnaire($questionnaireId: ID!) {
  deleteQuestionnaire(questionnaireId: $questionnaireId) {
    id
    title
    status
    description
    createdAt
    updatedAt
    qualities {
      id
      questionnaireCount
    }
  }
}
    `;
export type DeleteQuestionnaireMutationFn = Apollo.MutationFunction<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;

/**
 * __useDeleteQuestionnaireMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionnaireMutation, { data, loading, error }] = useDeleteQuestionnaireMutation({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useDeleteQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>(DeleteQuestionnaireDocument, options);
      }
export type DeleteQuestionnaireMutationHookResult = ReturnType<typeof useDeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationResult = Apollo.MutationResult<DeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;
export const OpenQuestionnaireDocument = gql`
    mutation OpenQuestionnaire($questionnaireId: ID!) {
  openQuestionnaire(questionnaireId: $questionnaireId) {
    id
    status
  }
}
    `;
export type OpenQuestionnaireMutationFn = Apollo.MutationFunction<OpenQuestionnaireMutation, OpenQuestionnaireMutationVariables>;

/**
 * __useOpenQuestionnaireMutation__
 *
 * To run a mutation, you first call `useOpenQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openQuestionnaireMutation, { data, loading, error }] = useOpenQuestionnaireMutation({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useOpenQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<OpenQuestionnaireMutation, OpenQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpenQuestionnaireMutation, OpenQuestionnaireMutationVariables>(OpenQuestionnaireDocument, options);
      }
export type OpenQuestionnaireMutationHookResult = ReturnType<typeof useOpenQuestionnaireMutation>;
export type OpenQuestionnaireMutationResult = Apollo.MutationResult<OpenQuestionnaireMutation>;
export type OpenQuestionnaireMutationOptions = Apollo.BaseMutationOptions<OpenQuestionnaireMutation, OpenQuestionnaireMutationVariables>;
export const CompleteQuestionnaireDocument = gql`
    mutation CompleteQuestionnaire($questionnaireId: ID!) {
  completeQuestionnaire(questionnaireId: $questionnaireId) {
    id
    status
  }
}
    `;
export type CompleteQuestionnaireMutationFn = Apollo.MutationFunction<CompleteQuestionnaireMutation, CompleteQuestionnaireMutationVariables>;

/**
 * __useCompleteQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCompleteQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeQuestionnaireMutation, { data, loading, error }] = useCompleteQuestionnaireMutation({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useCompleteQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<CompleteQuestionnaireMutation, CompleteQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteQuestionnaireMutation, CompleteQuestionnaireMutationVariables>(CompleteQuestionnaireDocument, options);
      }
export type CompleteQuestionnaireMutationHookResult = ReturnType<typeof useCompleteQuestionnaireMutation>;
export type CompleteQuestionnaireMutationResult = Apollo.MutationResult<CompleteQuestionnaireMutation>;
export type CompleteQuestionnaireMutationOptions = Apollo.BaseMutationOptions<CompleteQuestionnaireMutation, CompleteQuestionnaireMutationVariables>;
export const InviteParticipantsDocument = gql`
    mutation InviteParticipants($questionnaireId: ID!, $participants: [ParticipantInput!]!) {
  inviteParticipants(
    questionnaireId: $questionnaireId
    participants: $participants
  ) {
    id
    firstName
    lastName
    email
  }
}
    `;
export type InviteParticipantsMutationFn = Apollo.MutationFunction<InviteParticipantsMutation, InviteParticipantsMutationVariables>;

/**
 * __useInviteParticipantsMutation__
 *
 * To run a mutation, you first call `useInviteParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteParticipantsMutation, { data, loading, error }] = useInviteParticipantsMutation({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      participants: // value for 'participants'
 *   },
 * });
 */
export function useInviteParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<InviteParticipantsMutation, InviteParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteParticipantsMutation, InviteParticipantsMutationVariables>(InviteParticipantsDocument, options);
      }
export type InviteParticipantsMutationHookResult = ReturnType<typeof useInviteParticipantsMutation>;
export type InviteParticipantsMutationResult = Apollo.MutationResult<InviteParticipantsMutation>;
export type InviteParticipantsMutationOptions = Apollo.BaseMutationOptions<InviteParticipantsMutation, InviteParticipantsMutationVariables>;
export const VerifyRegistrationTokenDocument = gql`
    query VerifyRegistrationToken($token: String!) {
  verifyRegistrationToken(token: $token) {
    id
    description
    title
    status
    user {
      id
      firstName
      lastName
      fullName
    }
  }
}
    `;

/**
 * __useVerifyRegistrationTokenQuery__
 *
 * To run a query within a React component, call `useVerifyRegistrationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyRegistrationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyRegistrationTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyRegistrationTokenQuery(baseOptions: Apollo.QueryHookOptions<VerifyRegistrationTokenQuery, VerifyRegistrationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyRegistrationTokenQuery, VerifyRegistrationTokenQueryVariables>(VerifyRegistrationTokenDocument, options);
      }
export function useVerifyRegistrationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyRegistrationTokenQuery, VerifyRegistrationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyRegistrationTokenQuery, VerifyRegistrationTokenQueryVariables>(VerifyRegistrationTokenDocument, options);
        }
export type VerifyRegistrationTokenQueryHookResult = ReturnType<typeof useVerifyRegistrationTokenQuery>;
export type VerifyRegistrationTokenLazyQueryHookResult = ReturnType<typeof useVerifyRegistrationTokenLazyQuery>;
export type VerifyRegistrationTokenQueryResult = Apollo.QueryResult<VerifyRegistrationTokenQuery, VerifyRegistrationTokenQueryVariables>;
export const RegisterWithTokenDocument = gql`
    mutation RegisterWithToken($input: RegisterWithTokenInput!, $createSession: Boolean) {
  registerWithToken(input: $input, createSession: $createSession) {
    user {
      id
      username
      firstName
      lastName
      fullName
    }
    token
  }
}
    `;
export type RegisterWithTokenMutationFn = Apollo.MutationFunction<RegisterWithTokenMutation, RegisterWithTokenMutationVariables>;

/**
 * __useRegisterWithTokenMutation__
 *
 * To run a mutation, you first call `useRegisterWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerWithTokenMutation, { data, loading, error }] = useRegisterWithTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *      createSession: // value for 'createSession'
 *   },
 * });
 */
export function useRegisterWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<RegisterWithTokenMutation, RegisterWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterWithTokenMutation, RegisterWithTokenMutationVariables>(RegisterWithTokenDocument, options);
      }
export type RegisterWithTokenMutationHookResult = ReturnType<typeof useRegisterWithTokenMutation>;
export type RegisterWithTokenMutationResult = Apollo.MutationResult<RegisterWithTokenMutation>;
export type RegisterWithTokenMutationOptions = Apollo.BaseMutationOptions<RegisterWithTokenMutation, RegisterWithTokenMutationVariables>;
export const ReportErrorDocument = gql`
    mutation ReportError($error: String!) {
  reportError(error: $error)
}
    `;
export type ReportErrorMutationFn = Apollo.MutationFunction<ReportErrorMutation, ReportErrorMutationVariables>;

/**
 * __useReportErrorMutation__
 *
 * To run a mutation, you first call `useReportErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportErrorMutation, { data, loading, error }] = useReportErrorMutation({
 *   variables: {
 *      error: // value for 'error'
 *   },
 * });
 */
export function useReportErrorMutation(baseOptions?: Apollo.MutationHookOptions<ReportErrorMutation, ReportErrorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportErrorMutation, ReportErrorMutationVariables>(ReportErrorDocument, options);
      }
export type ReportErrorMutationHookResult = ReturnType<typeof useReportErrorMutation>;
export type ReportErrorMutationResult = Apollo.MutationResult<ReportErrorMutation>;
export type ReportErrorMutationOptions = Apollo.BaseMutationOptions<ReportErrorMutation, ReportErrorMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    username
    firstName
    lastName
    fullName
    roles {
      id
      capabilities
    }
    groups {
      id
      name
    }
    sessionScope
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UsersDocument = gql`
    query Users($filter: UserFilterInput, $pagination: PaginationInput) {
  users(filter: $filter, pagination: $pagination) {
    from
    rows
    items {
      ... on User {
        id
        username
        firstName
        lastName
        fullName
        email
        roles {
          id
          capabilities
        }
        groups {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserCountDocument = gql`
    query UserCount($filter: UserFilterInput) {
  userCount(filter: $filter)
}
    `;

/**
 * __useUserCountQuery__
 *
 * To run a query within a React component, call `useUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserCountQuery(baseOptions?: Apollo.QueryHookOptions<UserCountQuery, UserCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCountQuery, UserCountQueryVariables>(UserCountDocument, options);
      }
export function useUserCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCountQuery, UserCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCountQuery, UserCountQueryVariables>(UserCountDocument, options);
        }
export type UserCountQueryHookResult = ReturnType<typeof useUserCountQuery>;
export type UserCountLazyQueryHookResult = ReturnType<typeof useUserCountLazyQuery>;
export type UserCountQueryResult = Apollo.QueryResult<UserCountQuery, UserCountQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($userId: ID!) {
  user(userId: $userId) {
    id
    username
    email
    firstName
    lastName
    fullName
    roles {
      id
      capabilities
    }
    groups {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const AuthenticateDocument = gql`
    mutation Authenticate($input: AuthenticateInput!) {
  authenticate(input: $input) {
    user {
      id
      username
      firstName
      lastName
      fullName
      roles {
        id
        capabilities
      }
      groups {
        id
        name
      }
      sessionScope
    }
    token
  }
}
    `;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const DeauthenticateDocument = gql`
    mutation Deauthenticate {
  deauthenticate
}
    `;
export type DeauthenticateMutationFn = Apollo.MutationFunction<DeauthenticateMutation, DeauthenticateMutationVariables>;

/**
 * __useDeauthenticateMutation__
 *
 * To run a mutation, you first call `useDeauthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeauthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deauthenticateMutation, { data, loading, error }] = useDeauthenticateMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeauthenticateMutation(baseOptions?: Apollo.MutationHookOptions<DeauthenticateMutation, DeauthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeauthenticateMutation, DeauthenticateMutationVariables>(DeauthenticateDocument, options);
      }
export type DeauthenticateMutationHookResult = ReturnType<typeof useDeauthenticateMutation>;
export type DeauthenticateMutationResult = Apollo.MutationResult<DeauthenticateMutation>;
export type DeauthenticateMutationOptions = Apollo.BaseMutationOptions<DeauthenticateMutation, DeauthenticateMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: ID!) {
  deleteUser(userId: $userId) {
    id
    username
    firstName
    lastName
    email
    roles {
      id
      capabilities
    }
    groups {
      id
      name
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {
  updateUser(userId: $userId, input: $input) {
    id
    username
    firstName
    lastName
    email
    roles {
      id
      capabilities
    }
    groups {
      id
      name
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    username
    firstName
    lastName
    email
    roles {
      id
      capabilities
    }
    groups {
      id
      name
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($usernameOrEmail: String!) {
  requestPasswordReset(usernameOrEmail: $usernameOrEmail)
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      usernameOrEmail: // value for 'usernameOrEmail'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String, $password: String!) {
  resetPassword(token: $token, password: $password)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;