/* eslint import/no-webpack-loader-syntax: off */

import { useEffect, useState } from "react";
import styles from './ThankYou.module.scss';
import hulderApple from "!!file-loader!../resources/hulder-apple.webm";
import hulderAppleApng from "!!file-loader!../resources/hulder-apple.png";

export const ThankYou: React.FC<{ fullName?: string }> = ({ fullName }) => {
  const [fallback, setFallback] = useState(false);
  const [animationHidden, setAnimationHidden] = useState(false);

  useEffect(() => {
    const video = document.createElement("video");
    if (!video.canPlayType("video/webm")) setFallback(true);

    setTimeout(() => {
      setAnimationHidden(true);
    }, 4000);
  }, []);

  return (
    <div className={styles["container"]}>
      <div className={styles["completion-page-body"]}>
        <h1>Waardering ontvangen</h1>
        <h2>{`Bedankt namens ${
          fullName || "de vraagsteller"
        } voor het uiten van jouw waardering!`}</h2>
        {fallback ? (
          <img
            alt="Gouden appel"
            className={[
              styles["apple-anim"],
              animationHidden ? styles["hidden"] : null,
            ].join(" ")}
            src={hulderAppleApng}
          />
        ) : (
          <video
            className={[
              styles["apple-anim"],
              animationHidden ? styles["hidden"] : null,
            ].join(" ")}
            src={hulderApple}
            muted
            loop
            autoPlay
          />
        )}
      </div>
    </div>
  );
};

export default ThankYou;