import { TweenUpdate } from "@hulder/client-common/components/TweenUpdate";
import React from 'react';
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { WebSocketProvider } from './context/websocket';
import CreateQuestionnaire from './pages/CreateQuestionnaire';
import Index from './pages/Index';
import ViewResults from './pages/ViewResults';
import Vote from './pages/Vote';
import VotesSubmitted from './pages/VotesSubmitted';
import { store } from './redux';

function App() {
  return <>
    <TweenUpdate />
    <StoreProvider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route exact path="/aanmaken">
            <CreateQuestionnaire />
          </Route>
          <Route exact path="/resultaten">
            <WebSocketProvider>
              <ViewResults />
            </WebSocketProvider>
          </Route>
          <Route exact path="/stemmen/:id">
            <Vote />
          </Route>
          <Route exact path="/bedankt">
            <VotesSubmitted />
          </Route>
        </Switch>
      </Router>
    </StoreProvider>
  </>
}

export default App;
