import React from "react";
import styles from "./QualityCheckbox.module.scss";

export interface CheckboxProps {
  className: string;
}

export const Checkbox: React.FC<React.ComponentProps<"input">> = ({
  className,
  ...props
}) => {
  return (
    <label>
      <input className={styles['checkbox-input']} type="checkbox" {...props} />
      <svg
        className={styles['checkbox-check']}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="27.8px"
        height="27.8px"
        viewBox="0 0 27.8 27.8"
      >
        <circle cx="13.9" cy="13.9" r="13.9" />
        <polygon points="22.1,9.7 11.5,20.4 5.5,14.5 7,13 11.5,17.4 20.6,8.3 " />
      </svg>
    </label>
  );
};

export default Checkbox;
