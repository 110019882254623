import React from "react";
import styles from './Checkbox.module.scss';

export interface CheckboxProps {
    className: string
}

export const Checkbox: React.FC<React.ComponentProps<'input'>> = ({ className, ...props }) => {
    return <label className={[className, styles.container].join(' ')}>
        <input className={styles.checkboxInput} type="checkbox" {...props} />
        <span className={styles.checkboxBox}><span className={styles.checkboxCheck}></span></span>
    </label>
}

export default Checkbox;